import { ThemeType } from '../../../../shared/enums';
import DarkHeaderLogo from '../images/logo-dark.png';
import HeaderLogo from '../images/logo-white.png';

const VectorLogo = undefined;

const defaultTheme = ThemeType.DARK;
const hasThemeSwitcher = true;
const hasNetworkSwitcher = true;
const hasWhatsappWidget = true;
const hasBanner = true;

const headerLogoSize = 144;
const FooterLogo = HeaderLogo;
const DarkFooterLogo = DarkHeaderLogo;
const footerLogoSize = 144;

export {
  VectorLogo,
  HeaderLogo,
  DarkHeaderLogo,
  headerLogoSize,
  FooterLogo,
  DarkFooterLogo,
  footerLogoSize,
  defaultTheme,
  hasThemeSwitcher,
  hasNetworkSwitcher,
  hasWhatsappWidget,
  hasBanner,
};
